<template>
  <div ref="mapWrapper" class="map-wrapper">
    <div id="view" ref="view">
      <div id="map" ref="mapImage" class="map-base">
        <inline-svg
          :src="require(`../../assets/images/gallery1/map-5.svg`)"
          @loaded="svgLoaded($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { gsap, Power2 } from "gsap";
import panzoom from "panzoom";
export default {
  data() {
    return {
      viewInstance: null,
    };
  },
  mounted() {
    const vm = this;
    this.$store.commit("updateCurrentMap", 5);
  },
  methods: {
    svgLoaded(e) {
      const vm = this;
      const tl = new gsap.timeline();
      tl.set(this.$refs.mapImage, {
        scale: 0.8,
        opacity: 0,
      });
      tl.set("#bars > line", {
        opacity: 0,
      });
      tl.set("#markers > *", {
        opacity: 0,
      });
      tl.set("#rivers", {
        y: "-=50px",
        opacity: 0,
      });
      tl.set("#names-layer > g", {
        opacity: 0,
      });
      tl.set(["#names-layer > g", "#names-layer > g > g:not(:nth-child(1))"], {
        opacity: 0,
      });
      tl.to(
        this.$refs.mapImage,
        {
          y: "15vh",
          duration: 3,
          opacity: 1,
          ease: Power2.easeInOut,
          onComplete: function() {
            if(!vm.$refs.view) return;
            vm.viewInstance = panzoom(vm.$refs.view, {
              zoomSpeed: 0.05,
              transformOrigin: { x: 0.5, y: 0.5 },
              minZoom: .8,
              maxZoom: 4,
            });
          },
        },
        "0"
      );
      tl.to(
        "#rivers",
        {
          duration: 1,
          y:0,
          opacity: 1,
          ease: Power2.easeOut,
        },
        "-=.5"
      );
      tl.to(
        "#markers > *",
        {
          duration: 1,
          opacity: 1,
          ease: Power2.easeOut,
          stagger: {
            amount: 1,
          },
        },
        "-=.5"
      );
      tl.to(
        "#bars > line",
        {
          duration: 0.5,
          y: 0,
          opacity: 1,
          ease: Power2.easeOut,
          stagger:{
            amount:3
          }
        },
        "-=1.5"
      );
      tl.to(
        "#names-layer > g",
        {
          duration: 2,
          opacity: 1,
          ease: Power2.easeOut,
          stagger: {
            amount: 2,
          },
          onComplete:function(){
            if (vm.$route.name == "Map-5") {
              vm.$store.commit("updateMapDialog", true);
            }
          },
        },
        "-=1.5"
      );
    },
  },
};
</script>

<style></style>
